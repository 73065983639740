import React from "react";
import Header from '../components/Header';
import Body from '../components/MainPageBody';
import Footer from '../components/MainPageFooter';
export default function Landing() {
    return (
        <div>
            <Header/>
            <Body/>
            <Footer/>
        </div>
    );
}
/*import React from "react";
import SignInOutContainer from './containers';
export default function Landing() {
    return (
        <div>
            <SignInOutContainer/>
        </div>
    );
}*/